<template>
    <b-container fluid class="py-5">
        <b-row>          
            <b-col cols="12" md="2">
                <b-form-group>
                    <b-form-select
                    v-model="paginador.pageSize"
                    :options="pageSizeOptions"
                    @change="searchItemsByCategory"
                    >
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col>
                <b-pagination 
                    size="md" 
                    pills 
                    :total-rows="paginador.total" 
                    v-model="paginador.page" 
                    :per-page="1" 
                    @input="changePage" 
                    first-number 
                    last-number>
                </b-pagination>
            </b-col>
        </b-row>
        <div>
            <div v-if="catalogo.length > 0 ">
                <card-item :items="catalogo" @item-info="itemInfo($event)" @add-item="openItemModal($event)" />
            </div>
            <b-alert v-else variant="danger" show>
               Lo sentimos, no se han encontrado resultados para su busqueda.
            </b-alert>
        </div>
        <template v-if="catalogo.length > 0">
            <div class="text-center pt-2">
                <b-pagination 
                    size="md" 
                    pills 
                    :total-rows="paginador.total" 
                    v-model="paginador.page" 
                    :per-page="1" 
                    @input="changePage" 
                    first-number 
                    last-number>
                </b-pagination>
            </div>
        </template>
        <add-item :open-modal="modalAdd" :item="item" @close-modal="closeModal" />
    </b-container>
</template>

<script>
import AddItem from '@/components/AddItemToCart/AddItem.vue';
import { mapState, mapMutations} from 'vuex'
import CardItem from '../components/CardItemBase.vue'
export default {
    data(){
        return{
            item: {},
            modalAdd: false,
            displayItem: 1,
            catalogo: [],
            paginador: {
                page: 1,
                total: 1,
                pageSize: 18,
                order: 0
            },
            pageSizeOptions: [
                { value: 18, text: '18' },
                { value: 24, text: '24' },
                { value: 48, text: '48' }
            ],
            orderOptions: [
                { value: 0, text: 'Orden inicial' },
                { value: 1, text: 'Productos (A-Z)' },
                { value: 2, text: 'Productos (Z-A)' }
            ]
        }
    },
    components: {
        AddItem,
        CardItem
    },
    watch: {
        '$route.params.time' : function () {
        this.searchItemsByCategory();
        this.paginador.page = 1;
        }
    },
    created: function () {
        this.searchItemsByCategory()
    },
    computed: {
        ...mapState('configuration', ['background', 'typeSearch']),
        cssVars() {
            return {
                '--border-bottom': this.background
            };
        },
    },
    methods:{
        ...mapMutations(['setLoading']),
        async searchItemsByCategory(){
            this.setLoading(true)
            let search = this.$route.params.texto;
            let pageNumber = this.paginador.page;
            let pageSize =  this.paginador.pageSize;
            let typeSearch = this.typeSearch;
            let orderType = this.paginador.order;
            
            let criterioBusqueda = { search, pageNumber, pageSize, typeSearch, orderType};
            await this.$conectar.post('/busqueda/search-items-empresa-hoyostools', criterioBusqueda)
            .then(response =>{
                this.catalogo = response.data.items;
                this.paginador.total = response.data.totalPages;
                
            })
            .catch(error => {
                console.log(error);
            })
            this.setLoading(false)
        },
        changePage(page){
            this.paginador.page = page;
            this.searchItemsByCategory();
        },
        itemInfo(datos){
            if(datos.empresa_id === 0){
                this.$router.push({ name: 'ItemDetail', params:{ referencia: datos.codigo} });
            }else{
                this.$router.push({ name: 'itemEmpresaDetail', params:{ codigo: datos.codigo} });
            }
        },
        openItemModal(item){
            this.item.itemName = item.nombre;
            this.item.itemImage = item.imagen;
            if (typeof item.empresa_id !== 'undefined' && item.empresa_id !== 0){
                this.item.reference = item.codigo;
                this.item.empresa_id = item.empresa_id;
            } else {
                this.item.reference = item.codigo;
                this.item.quantity = item.quantity;
            }
            this.modalAdd = true;
        },
        closeModal(){
            this.item = {};
            this.modalAdd = false;
        },
        typeViewAction(typeView){
            this.displayItem = typeView;
        }
        
    }
}
</script>


<style scoped>

.barra-nuevos-productos{
    background-color: #fffffc;
    background-image: linear-gradient(200deg, #fffffc 0%, #cac7c7 84%);
    border-bottom: 5px solid var(--border-bottom);
}

.only-mobile-display{
    display:none
}

@media screen and (max-width: 800px) {
    .tipo-vistas-card{
        display: none;
    }
    .only-mobile-display{
        display:initial
    }
}




</style>